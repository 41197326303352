import React, { createContext, FC, useState, useEffect } from 'react';

import {
  GTMProviderProps,
  GTMState,
  FilterEvent,
  FilterEventPayload,
  NavigationEvent,
  AppName,
  ProcessType,
  ProcessEvent,
  ProcessEventPayload,
  EcommerceEvent,
  EcommerceEventPayload,
  PageViewEvent,
  PageViewPayload,
  FileDownloadPayload,
  FileDownloadEvent,
} from '../types';
import TagManager from 'react-gtm-module';
import { TAG_MANAGER_INITIALIZER, STOCK_STATUS_MAP } from '../constants';

const GTMContext = createContext<GTMState>({ isReadyToSendEvents: false});
/**
 * Provides methods to trigger ga4 events
 * Initializes gtm script with correct params depending on environment string passed
 * @param children
 * @param env
 * @constructor
 */
const GTMProvider: FC<GTMProviderProps> = ({ children, env }) => {

  const [appName, setAppName] = useState<AppName>();
  const [processType, setProcessType] = useState<ProcessType>();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    /**
     * Checks if the ga script tag with appropriate gtmId is present
     * TODO: change the condition to more accurate one, this one only checks if id is present in src string
     * @return boolean
     */
    const isInitialized = () => {
      const scripts = document.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.includes(TAG_MANAGER_INITIALIZER[env].gtmId)) {
          return true
        }
      }
      return false;
    }
    // initializes the script tag, check is needed because there are multiple re-renders
    if (!isInitialized()) {
      TagManager.initialize(TAG_MANAGER_INITIALIZER[env]);
    }

    // sets app name
    if (appName === undefined || appName !== process.env.REACT_APP_NAME) {
      setAppName(process.env.REACT_APP_NAME as AppName)
    }
    const calculators = ['tco motor calculator', 'gasket calculator'];
    const selectors = ['o ring selector', 'filter element selector', 'valve selector'];
    const configurators = ['plastics calculator', 'gasket configurator']
    if (calculators.findIndex((str) => (str === process.env.REACT_APP_NAME)) > -1) {
      setProcessType('calculator');
    }
    if (selectors.findIndex((str) => (str === process.env.REACT_APP_NAME)) > -1) {
      setProcessType('selector');
    }
    if (configurators.findIndex((str) => (str === process.env.REACT_APP_NAME)) > -1) {
      setProcessType('configurator');
    }

  },[])

  useEffect(() => {
    if(appName && processType && !isReady) {
      setIsReady(true)
    }
  }, [appName, processType, isReady])

  // stubs for the methods that will be exposed
  const sendPageViewEvent = ({ pageUrl, pagePath, pageHostname,  organizationId, organizationName, userType, userId, userSegment,
                               userSalesChannel, loggedIn }: PageViewPayload) => {
    const event: PageViewEvent = {
      event: 'page_view',
      page_type: processType,
      page_url: pageUrl.toLowerCase(),
      page_path: pagePath.toLowerCase(),
      page_hostname: pageHostname.toLowerCase(),
      user_type: userType ? userType.toLowerCase() : '',
      user_id: userId ? userId.toLowerCase() : '',
      user_segment: userSegment ? userSegment.toLowerCase() : '',
      user_login_status: loggedIn ? 'logged in' : 'not logged in',
      user_sales_channel:userSalesChannel ? userSalesChannel.toLowerCase() : '',
      organization_id: organizationId ? organizationId.toLowerCase() : '',
      organization_name: organizationName ? organizationName.toLowerCase() : '',
    }
    TagManager.dataLayer({
      dataLayer: event,
    })
  }
  const sendProcessStartEvent = ({ processStepName }: ProcessEventPayload) => {
    const event: ProcessEvent = {
      event: 'process_start',
      process_type: processType,
      process_name: appName,
      process_step_name: processStepName,
    }
    TagManager.dataLayer({
      dataLayer: event,
    })
  }
  const sendProcessInteractionEvent = ({ processStepName }: ProcessEventPayload) => {
    const event: ProcessEvent = {
      event: 'process_interaction',
      process_type: processType,
      process_name: appName,
      process_step_name: processStepName,
    }
    TagManager.dataLayer({
      dataLayer: event,
    })
  }
  const sendProcessFinishEvent = ({ processStepName }: ProcessEventPayload) => {
    const event: ProcessEvent = {
      event: 'process_finish',
      process_type: processType,
      process_name: appName,
      process_step_name: processStepName,
    }
    TagManager.dataLayer({
      dataLayer: event,
    })
  }
  const sendAddToCartEvent = ({
    itemName,
    itemId,
    price,
    itemBrand,
    itemStockStatus,
    index,
    quantity,
    materialNumber
  }: EcommerceEventPayload) => {
    const event: EcommerceEvent = {
      item_name: itemName ? itemName.toLowerCase() : '',
      item_id: itemId ? itemId.toLowerCase() : '',
      price,
      item_brand: itemBrand ? itemBrand.toLowerCase() : '',
      item_stockstatus: STOCK_STATUS_MAP[itemStockStatus],
      index,
      item_variant: 'pce',
      quantity,
      item_list_name: appName,
      material_number: materialNumber
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          items: [event]
        }
      }
    })
  }

  const sendSelectItemEvent = ({
    itemName,
    itemId,
    price,
    itemBrand,
    itemStockStatus,
    index,
    materialNumber,
    isLoggedIn,
  }: EcommerceEventPayload) => {
    const event: EcommerceEvent = {
      item_name: itemName ? itemName.toLowerCase() : '',
      item_id: itemId ? itemId.toLowerCase() : '',
      price: isLoggedIn ? price : undefined,
      item_brand: itemBrand ? itemBrand.toLowerCase() : '',
      item_stockstatus: STOCK_STATUS_MAP[itemStockStatus],
      index,
      item_variant: 'pce',
      item_list_name: appName,
      material_number: materialNumber
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          items: [event]
        }
      }
    })
  }

  const sendViewItemEvent = (events: EcommerceEventPayload[]) => {
    const items: EcommerceEvent[] = [];
    for (let i = 0; i < events.length; i++) {
      const event: EcommerceEvent = {
        item_name: events[i].itemName ? events[i].itemName.toLowerCase() : '',
        item_id: events[i].itemId ? events[i].itemId.toLowerCase() : '',
        price: events[i].price,
        item_brand: events[i].itemBrand ? events[i].itemBrand.toLowerCase() : '',
        item_stockstatus: STOCK_STATUS_MAP[events[i].itemStockStatus],
        index: events[i].index,
        item_variant: 'pce',
        item_list_name: appName,
        material_number: events[i].materialNumber,
      }
      items.push(event)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item_list',
        ecommerce: {
          items,
        }
      }
    })
  }
  const sendAddFilterEvent = ({ name, category } : FilterEventPayload) => {
    const event: FilterEvent = {
      event: 'select_filter',
      filter_name: name.toLowerCase(),
      filter_category: category.toLowerCase(),
    }
    TagManager.dataLayer({
      dataLayer: event
    })
  }
  const sendRemoveFilterEvent = ({ name, category }: FilterEventPayload) => {
    const event: FilterEvent = {
      event: 'remove_filter',
      filter_name: name.toLowerCase(),
      filter_category: category.toLowerCase(),
    }
    TagManager.dataLayer({
      dataLayer: event
    })
  }
  const sendNavigationEvent = () => {
    const event: NavigationEvent = {
      event: 'nav_pagination',
      nav_name: 'forward'
    }
    TagManager.dataLayer({
      dataLayer: event
    })
  }

  const sendFileDownloadEvent = ({ fileName }: FileDownloadPayload) => {
    const event: FileDownloadEvent = {
      event: 'file_download',
      download_type: 'product datasheet',
      file_name: fileName,
    }
    TagManager.dataLayer({
      dataLayer: event
    })
  }
  return (
    <GTMContext.Provider value={{
      sendPageViewEvent,
      sendProcessStartEvent,
      sendProcessInteractionEvent,
      sendProcessFinishEvent,
      sendSelectItemEvent,
      sendAddToCartEvent,
      sendAddFilterEvent,
      sendViewItemEvent,
      sendRemoveFilterEvent,
      sendNavigationEvent,
      sendFileDownloadEvent,
      isReadyToSendEvents: isReady,
    }}>
      {children}
    </GTMContext.Provider>
  )
};

export { GTMContext, GTMProvider, GTMProviderProps }
